import './herosection.css'; 
import bgVideo from '../../assets/bg-video.mp4';

const HeroSection = () => {
  return (
    <section id="home" className="hero-section">

      <div className='overlay'></div>

      <video playsinline="playsinline" autoplay="autoplay" muted="muted" loop="loop">
      <source src={ bgVideo } type="video/mp4" />
      </video>

      <div className="hero-content">
        <h1>Welcome to Thinkmint Europe</h1>
        <p>Bringing Nigerian Real Estate to Europe</p>
        <a href="https://www.thinkmint.ng" className="cta-button">Get Started</a>
      </div>
    </section>
  );
};

export default HeroSection;