import './testimonial.css';
import Slider from "react-slick";


const Testimonial = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    return ( 
        <section id="testimonial" className="testimonial-section">
            <h2>Testimonials</h2>

            <div className="slider-container container">
            <Slider { ...settings } className=' testimonial-group'>
                <div className='testimonial-item'>
                    <h4 className='testimony-author'>William F.</h4>
                    <h5 className='testimony-address'> 🇳🇬 🇧🇪 </h5>
                    <p>Many thanks to Thinkmint for helping me secure my property in Nigeria, after contacting your team through your property advert on Facebook. Your company extremely supportive and encouraged me as I was given the flexible payment option over 12 months. Indeed, before the 12 months period, I was allocated my plots in the Brookland estate. Kudos to the team even as I plan to acquire more properties.</p>
                </div>

                <div className='testimonial-item'>
                <h4 className='testimony-author'>Gbenga F.</h4>
                    <h5 className='testimony-address'> 🇳🇬 🇩🇪</h5>
                    <p>I saw an advert on social media by Thinkmint and contacted the team who thereafter scheduled an inspection of the prooperty with my sister in Nigeria. The team picked her up and dropped her off after the inspection. I commenced payment and was allocated my plots within my payment duration. I would recommend that you purchased your property in Nigeria through the team in Thinkmint.</p>
                </div>

                <div className='testimonial-item'>
                <h4 className='testimony-author'>Biouti Tzaferi.</h4>
                    <h5 className='testimony-address'> 🇳🇬 🏴󠁧󠁢󠁳󠁣󠁴󠁿</h5>
                    <p>I got introduced to Thinkmint by my brother-in-law as I needed to purchase some plots of land in Nigeria, Thinkmint handled the process for me with ease, as I have gone ahead to acquire more lands after then due to the huge return on investment. I will refer the team to anyone anyday, anytime, thank you.</p>
                </div>
            </Slider>
            </div>

        </section>
    );
}
 
export default Testimonial;