import './services.css';

const Services = () => {
  return (
    <section id="services" className="services-section">
      <h2>Our Services</h2>
      <div className="services-list">
        <div className="service-item">
          <h3>Property Sales</h3>
          <p>We help you find and purchase your ideal property.</p>
        </div>
        <div className="service-item">
          <h3>Property Rentals</h3>
          <p>Explore our rental listings to find your next home.</p>
        </div>
        <div className="service-item">
          <h3>Consulting</h3>
          <p>Get expert advice on the European real estate market.</p>
        </div>
      </div>
    </section>
  );
};

export default Services;