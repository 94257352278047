
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

import Header from './components/header/Header';
import AboutUs from './components/aboutus/AboutUs';
import HeroSection from './components/herosection/HeroSection';
import Footer from './components/footer/Footer';
import Contact from './components/contact/Contact';
import Services from './components/services/Services';
import Testimonial from './components/testimonial/Testimonial';

function App() {
  return (
    <div className="App">
      <Header />
      <HeroSection />
      <AboutUs />
      <Services />
      <Testimonial />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
