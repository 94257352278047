import './contact.css';
import React, {useState, useRef} from "react";

const Contact = () => {
  const formRef = useRef(null)
  const scriptURL = 'https://script.google.com/macros/s/AKfycbyMzTicWkpG8ryI1tjSds_NCaNynTB4wTyGHf2cXdYlHfiNurKfZrgwCEjb6pTPJWnf/exec'
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState('')
  const [formClass, setFormClass] = useState('')


  const handleSubmit = (e) => {
      e.preventDefault()
      setLoading(true)

      fetch(scriptURL, {
          method: 'POST',
          body: new FormData(formRef.current),
      }).then(res => {
          setMessage('Sent Successful!')
          setFormClass('success-message')

          setTimeout(() => {
              setFormClass('')
              setMessage('')
              }, 5000);

          setLoading(false)
          formRef.current.reset()
      })
  }

  return (
    <section id="contact" className="contact-section">
      <h2>Contact Us</h2>

      <div className="contact-group container">
      <form name="submit-to-google-sheet" ref={ formRef } onSubmit={handleSubmit} >
        <div className="form-group">
          <label htmlFor="name"></label>
          <input type="text" id="name" name="name" placeholder='Name' required />
        </div>
        <div className="form-group">
          <label htmlFor="email"></label>
          <input type="email" id="email" name="email" placeholder='Email' required />
        </div>
        <div className="form-group">
          <label htmlFor="message"></label>
          <textarea id="message" name="message" placeholder='Message' required></textarea>
        </div>
        <input type='submit' value={loading ? "Sending..." : "Send"} className='send-button' />

        <p className={ formClass }> { message } </p>
      </form>

      <div className="contact-address">
        <div>Narva Mnt 27, 51009, Tartu, Estonia</div>
        <div>+372 5824 5941</div>
        <div>info@thinkmint.eu</div>
      </div>
      </div>
     
    </section>
  );
};

export default Contact;