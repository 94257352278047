import './aboutus.css';

const AboutUs = () => {
  return (
    <section id="about" className="about-section">
      <div className='about-group container'>
      <h2>About Us</h2>
      <p>At Thinkmint Europe, we connect Nigerians in diaspora and people looking to invest in Nigeria with the best real estate projects in the country.</p>
      <p>We are positioned to ensure that the process involved in buying or selling a property is seamless.</p>
      </div>
    </section>
  );
};

export default AboutUs;