import './footer.css';

const Footer = () => {

  const thisYear = new Date().getFullYear() - 5

  return (
    <footer>
      <p> &copy; { thisYear } Thinkmint Europe. All rights reserved.</p>
    </footer>
  );
};

export default Footer;